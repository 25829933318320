var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-tabs-items', {
    attrs: {
      "value": _vm.tab,
      "touchless": ""
    }
  }, [_c('tab-select-type-customer', _vm._g({
    attrs: {
      "tab": _vm.tabs[0]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, {
    input: _vm.forward
  })), _c('tab-select-targets-at', _vm._g({
    attrs: {
      "tab": _vm.tabs[1],
      "title": "발송일을 선택해 주세요.",
      "hasType": ""
    },
    model: {
      value: _vm.form.targetsAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "targetsAt", $$v);
      },
      expression: "form.targetsAt"
    }
  }, {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-input-sender', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[2]
    },
    model: {
      value: _vm.form.sender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sender", $$v);
      },
      expression: "form.sender"
    }
  }, 'tab-input-sender', {
    targetsAt: _vm.targetsAt
  }, false), {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-input-receivers', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[3]
    },
    model: {
      value: _vm.orders,
      callback: function callback($$v) {
        _vm.orders = $$v;
      },
      expression: "orders"
    }
  }, 'tab-input-receivers', {
    targetsAt: _vm.targetsAt,
    isBulkOrder: _vm.isBulkOrder
  }, false), {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-select-products', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[4],
      "targetsAtText": "발송일",
      "backwardText": "받으시는 분 수정"
    },
    model: {
      value: _vm.orders,
      callback: function callback($$v) {
        _vm.orders = $$v;
      },
      expression: "orders"
    }
  }, 'tab-select-products', {
    form: _vm.form,
    targetsAt: _vm.targetsAt,
    isBulkOrder: _vm.isBulkOrder
  }, false), {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-attach-files', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[5],
      "targetsAtText": "발송일"
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'tab-attach-files', {
    targetsAt: _vm.targetsAt
  }, false), {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-confirm-customer', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[6]
    }
  }, 'tab-confirm-customer', {
    form: _vm.form,
    orders: _vm.orders,
    loading: _vm.loading
  }, false), {
    backward: _vm.backward,
    forward: _vm.post
  }))], 1), _c('alert-dialog', _vm._g(_vm._b({
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'alert-dialog', {
    message: _vm.message
  }, false), {
    close: _vm.close
  }))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <client-page>
        <v-tabs-items :value="tab" touchless>
            <tab-select-targets-at
                v-model="form.targetsAt"
                :tab="tabs[0]"
                v-bind="{ allowedDates }"
                v-on="{
                    forward,
                }"
            />
            <tab-select-products
                :value="[form]"
                :tab="tabs[1]"
                v-bind="{ ...form, form }"
                v-on="{
                    input: (orders) => (form = orders[0]),
                    backward,
                    forward,
                }"
            />
            <tab-confirm-order-default
                :tab="tabs[2]"
                v-bind="{ targetsAt, products, loading }"
                v-on="{
                    backward,
                    forward: post,
                }"
            />
        </v-tabs-items>
        <alert-dialog v-model="shows" v-bind="{ message }" v-on="{ close }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import AlertDialog from "@/components/client/dumb/alert-dialog.vue";
import TabSelectProducts from "@/components/client/purchase-ordering/tab-select-products/tab-select-products.vue";
import TabSelectTargetsAt from "@/components/client/purchase-ordering/tab-select-targets-at.vue";
import TabConfirmOrderDefault from "@/components/client/purchase-ordering/tab-confirm-order-default.vue";
import dayjs from "dayjs";

let initForm = () => ({
    type: PURCHASE_ORDER_TYPES.GIFTSET_ORDER.value,
    targetsAt: null,
    products: [],
});
let pagePath = "/purchase-ordering/giftset-order";
let tabs = ["select-targets-at", "select-products", "confirm-order"];
let allowedDates = (date) => [2, 4, 6].includes(dayjs(date).day());

export default {
    components: {
        ClientPage,
        PageSection,
        AlertDialog,
        TabSelectProducts,
        TabSelectTargetsAt,
        TabConfirmOrderDefault,
    },
    props: {
        tab: { type: String, default: tabs[0] },
    },
    data: () => ({
        tabs,
        shows: true,
        isDone: false,
        loading: false,
        succeeded: undefined,
        allowedDates,
        message: "발주 가능시간은 18시 ~ 명일 15시입니다.\n위 발주 가능시간 이내에 발주를 완료해주시기 바랍니다.",

        form: initForm(),
    }),
    computed: {
        targetsAt() {
            return this.form.targetsAt;
        },
        products() {
            return this.form.products;
        },
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setShowsNavigation(false);
        this.setTitle("세트발주 - 입고일선택");
        this.reroute();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setShowsNavigation(true);
        this.setTitle();
    },
    watch: {
        tab() {
            switch (this.tab) {
                case "select-targets-at":
                    this.setTitle("세트발주 - 입고일선택");
                    break;
                case "select-products":
                    this.setTitle("세트발주 - 상품선택");
                    break;
                case "confirm-order":
                    this.setTitle("세트발주 - 발주내용확인");
                    break;
                default:
                    this.setTitle("세트발주");
                    break;
            }
            this.reroute();
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
            setShowsNavigation: "setShowsNavigation",
        }),
        reroute() {
            if (this.tab != "select-targets-at" && !this.form.targetsAt) {
                let index = tabs.findIndex((item) => item == this.tab);
                if (0 < index) this.$router.go(index * -1);
            }
            let path = `${pagePath}/${this.tab}`;
            if (this.$route.path != path) {
                this.$router.replace({ path });
            }
        },
        backward() {
            this.$router.go(-1);
        },
        forward() {
            let thisTabIndex = tabs.findIndex((item) => item == this.tab);
            let nextTab = tabs[thisTabIndex + 1];
            let path = `${pagePath}/${nextTab}`;
            this.$router.push({ path });
        },
        close() {
            this.shows = false;
            if (this.isDone) {
                let path = this.succeeded ? "/mypage/purchase-orders" : "/";
                this.$router.push({ path });
            }
        },
        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.purchaseOrders.post(this.form);
                this.message = "발주가 완료되었습니다.";
                this.succeeded = true;
            } catch {
                this.message = "발주 가능시간이 아닙니다.\n18시 ~ 명일 15시에 다시\n발주해 주시기 바랍니다.";
                this.succeeded = false;
            } finally {
                this.loading = false;
                this.isDone = true;
                this.shows = true;
            }
        },
    },
};
</script>

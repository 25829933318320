var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('v-tabs-items', {
    attrs: {
      "value": _vm.tab,
      "touchless": ""
    }
  }, [_c('tab-select-type-store', _vm._g({
    attrs: {
      "tab": _vm.tabs[0]
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, {
    input: _vm.forward
  })), _c('tab-select-targets-at', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[1],
      "hasType": ""
    },
    model: {
      value: _vm.form.targetsAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "targetsAt", $$v);
      },
      expression: "form.targetsAt"
    }
  }, 'tab-select-targets-at', {
    allowedDates: _vm.allowedDates
  }, false), {
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-select-products', _vm._g(_vm._b({
    attrs: {
      "value": [_vm.form],
      "tab": _vm.tabs[2],
      "backwardText": _vm.isCompanyOrder ? undefined : '유형 변경'
    }
  }, 'tab-select-products', {
    form: _vm.form,
    targetsAt: _vm.targetsAt,
    hideTargetsAt: _vm.hideTargetsAt
  }, false), {
    input: function input(orders) {
      return _vm.form = orders[0];
    },
    backward: _vm.backward,
    forward: _vm.forward
  })), _c('tab-confirm-order-default', _vm._g(_vm._b({
    attrs: {
      "tab": _vm.tabs[3]
    }
  }, 'tab-confirm-order-default', {
    targetsAt: _vm.targetsAt,
    products: _vm.products,
    hideTargetsAt: _vm.hideTargetsAt,
    loading: _vm.loading
  }, false), {
    backward: _vm.backward,
    forward: _vm.post
  }))], 1), _c('alert-dialog', _vm._g(_vm._b({
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, 'alert-dialog', {
    message: _vm.message
  }, false), {
    close: _vm.close
  }))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }